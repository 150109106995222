<template>
  <div class="container-fluid px-0" v-if="transfer != 'file transfer'">
    <b-card no-body>
      <b-card-header class="d-flex flex-row align-items-center">
        File Transfer
      </b-card-header>
      <b-card-body>

        <div>
            <label for="coordinate-header">
              Enter longitude and latitude to return list of locations centered within 300 miles of the coordinates
            </label>
              <form class="form-inline">
                <div class="form-group ml-5 my-3">
                  <b-form-group
                      label-cols-sm="2"
                      label="Latitude:"
                      label-align-sm="right"
                      label-for="latitude"
                    >
                      <b-form-input v-model="form['latitude']" id="latitude" class="ml-2"></b-form-input>
                    </b-form-group>
                </div>
                <div class="form-group mx-5">
                  <b-form-group
                      label-cols-sm="2"
                      label="Longitude:"
                      label-align-sm="right"
                      label-for="longitude"
                    >
                      <b-form-input v-model="form['longitude']" id="longitude" class="ml-2"></b-form-input>
                    </b-form-group>
                </div>
                <b-button id="coordinateSub" variant="primary" @click="handleFetch">Enter</b-button>
              </form>
        </div>

        <b-row>
          <b-col cols="6">
            <div v-if="items.length>0 && items!='empty'">
              <div id='listofLocations'>
                List of locations within 300 miles of given coordinates:
              </div>
              <b-table bordered hover :items="items" id="dbTable"></b-table>
              <b-button id="newWeather" variant="primary" @click="handleTransfer">New weather file</b-button>
            </div>

            <div v-if="items=='empty'">
              <div id="noLocationError">
                No location exists within 300 miles of the given coordinates
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>


  <div v-else-if="transfer == 'file transfer'">
    <div id='fileUpload'>
    <b-card no-body>
      <b-card-header class="d-flex flex-row align-items-center">
        File Upload
      </b-card-header>
      <b-card-body>

        <div>
            <label for="coordinate-header">Enter location name for file you wish to upload</label>
              <form>
                <div class="form-group my-3">
                  <b-form-group
                      label-cols-sm="2"
                      label="Location:"
                      label-align-sm="left"
                      label-for="location"
                    >
                      <b-form-input v-model="form['location']" id="location"></b-form-input>
                    </b-form-group>
                </div>
                <div class="form-group">
                  <b-form-group
                      label-cols-sm="2"
                      label="Comments:"
                      label-align-sm="left"
                      label-for="comments"
                    >
                      <b-form-input v-model="form['comments']" id="comments"></b-form-input>
                    </b-form-group>
                </div>
                <label for="coordinate-header" id='selectWeather'>Select weather file (.epw)</label>
                <b-form-file v-model="file2" class="mt-3" plain></b-form-file>
                <label for="coordinate-header" id='uploadWeather'>Upload weather file to database</label>
                <div class="form-group my-2">
                  <b-button :pressed.sync="myToggle" variant="primary">Set to public</b-button>
                </div>
                <div class="form-group my-3">
                  <b-button id="coordinateSub" variant="primary" @click="handleUpload">Upload</b-button>
                </div>
              </form>
        </div>
        <div id='displayIdUpload' v-if="locationIdUpload['id']">
          <p id="uploadOutput">{{locationIdUpload}}</p>
        </div>
      </b-card-body>
    </b-card>
    </div>

    <div id='fileDownload'>
    <b-card no-body>
      <b-card-header class="d-flex flex-row align-items-center">
        File Download
      </b-card-header>
      <b-card-body>

        <div>
            <label for="coordinate-header">Enter data source, filetype, coordinates and comments for file you wish to download</label>
              <form>
                <div class="form-group my-3">
                  <b-form-group
                      label-cols-sm="2"
                      label="Data source:"
                      label-align-sm="left"
                      label-for="datasource"
                    >
                      <b-form-input v-model="dsrc" id="datasource"></b-form-input>
                    </b-form-group>
                </div>
                <div class="form-group">
                  <b-form-group
                      label-cols-sm="2"
                      label="Filetype:"
                      label-align-sm="left"
                      label-for="filetype"
                    >
                      <b-form-select
                        v-model="selected"
                        :options="options"
                        class="mb-3"
                        value-field="item"
                        text-field="name"
                        disabled-field="notEnabled"
                        id="filetype"
                      ></b-form-select>
                    </b-form-group>
                </div>
                <div class="form-group">
                  <b-form-group
                      label-cols-sm="2"
                      label="Longitude:"
                      label-align-sm="left"
                      label-for="longitude"
                    >
                      <b-form-input v-model="form['longitude']" id="lon"></b-form-input>
                    </b-form-group>
                </div>
                <div class="form-group">
                  <b-form-group
                      label-cols-sm="2"
                      label="Latitude:"
                      label-align-sm="left"
                      label-for="latitude"
                    >
                      <b-form-input v-model="form['latitude']" id="lat"></b-form-input>
                    </b-form-group>
                </div>
                <div class="form-group">
                  <b-form-group
                      label-cols-sm="2"
                      label="Comments:"
                      label-align-sm="left"
                      label-for="comments"
                    >
                      <b-form-input v-model="form['comments']" id="comments"></b-form-input>
                    </b-form-group>
                </div>
                <label for="coordinate-header" id='nrelDownload'>Download weather data from NREL Database</label>
                <div class="form-group my-3">
                  <b-button id="coordinateSub" variant="primary" @click="handleDownload">Download</b-button>
                </div>
              </form>
        </div>
        <div id='displayId' v-if="locationId['id']">
          <p id="demo">{{locationId}}</p>
        </div>
      </b-card-body>
    </b-card>
    </div>


  </div>
</template>

<script>

export default {
  name: 'file transfer',
  data() {
    return {
      form: {},
      items: [],
      transfer: '',
      locationId: [],
      locationIdUpload: [],
      myToggle: false,
      file2: null,
      dsrc: 'nsrdb',
      selected: 'A',
      options: [
        { item: 'A', name: 'v3' },
        { item: 'B', name: 'v3_tmy' }
      ]
    };
  },
  methods: {
    async handleFetch() {
      const res = await this.$pythonApi.get(`/location/?lat=${this.form.latitude}&lon=${this.form.longitude}&rng=300`);
      this.items = res;
      if (res.length === 0) {
        this.items = 'empty';
      }
    },
    async handleTransfer() {
      this.transfer = 'file transfer';
    },
    async handleDownload() {
      // eslint-disable-next-line
      const res = await this.$pythonApi.post(`/weather/import?dtype=${this.form.datasource}&lat=${this.form.latitude}&lon=${this.form.longitude}&filetype=${this.form.filetype}&notes=${this.form.comments}`);
      this.locationId = res;
    },
    async handleUpload() {
      console.log(this.file2);
      // eslint-disable-next-line
      const res = await this.$pythonApi.post(`/weather/upload?location=${this.form.location}&notes=${this.form.comments}&is_public=${this.myToggle}`, {
        body: await this.file2.text()
      });
      this.locationIdUpload = res;

    },
  }
};
</script>

<style scoped>
    #coordinateSub, #newWeather {
      background-color: #e7e7e7;
      color: black;
      border: 1px solid black;
    }
    #noLocationError {
      background-color: #F4F3F1;
      width: 600px;
      border: 20px solid #F4F3F1;
      margin-top: 25px;
      margin-bottom: 25px;
      margin-right: 15px;
      margin-left: 3px;
    }
    #displayId{
      background-color: #F4F3F1;
      width: 600px;
      border: 20px solid #F4F3F1;
      margin-top: 50px;
      margin-bottom: 25px;
      margin-right: 15px;
    }
    #displayIdUpload{
      background-color: #F4F3F1;
      width: 800px;
      border: 20px solid #F4F3F1;
      margin-top: 50px;
      margin-bottom: 25px;
      margin-right: 15px;
    }

    #listofLocations{
      background-color: #F4F3F1;
      width: 600px;
      border: 20px solid #F4F3F1;
      margin-top: 25px;
      margin-right: 15px;
      margin-left: 3px;
    }
    #dbTable{
      width: 500px;
      text-align: center;
      vertical-align: middle;
      background-color: #F4F3F1;
      width: 600px;
      border: 1px solid black;
      margin-bottom: 25px;
      margin-right: 15px;
      margin-left: 3px;
    }
    #location, #comments, #datasource, #filetype, #lon, #lat {
      width: 200px;
    }
    #selectWeather {
      margin-top: 35px;
    }
    #uploadWeather {
      margin-top:50px;
    }
    #fileDownload, #fileUpload{
    width:850px;
    height:100px;
    margin-right:40px;
    float:left;
    }
    #nrelDownload {
      margin-top: 50px;
    }

</style>
